export const Equipment =() => [
    {
      name: 'client_cust_id',
      col: 8,
      lable: 'Customer Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select Customer!',
          whitespace: true,
        },
      ],
    },
    {
      name: 'driver_name',
      lable: 'Driver Name',
      col: 8,
    },
    

    {
      name: 'trucking_carrier',
      lable: 'Truck Carrier',
      col: 8,
    },
    {
      name: 'equipment_no',
      lable: 'Equipment No.',
      col: 8,
      type: 'autocomplete',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Enter equipment number!',
          whitespace: true,
        },
      ],
    },
    {
      name:'custom1'
    },
    
    {
      name: 'quantity',
      lable: 'Quantity',
      type:'number',
      col: 8,
    },
    
    {
      name: 'generator',
      lable: 'From/Generator',
      col: 8,
      rules: [
        {
          required: true,
          message: 'Please Enter form generator!',
          whitespace: true,
        },
      ],
    },
    {
      name: 'last_known',
      lable: 'Hazardous',
      type: 'radio',
      col: 8,
      value: [
        { value: 'nonhaz', displayValue: 'No' },
        { value: 'haz', displayValue: 'Yes' },
      ],
      rules: [
        {
          required: true,
          message: 'Please Select Hazardous Category!',
          whitespace: true,
        },
      ],
    },
    {
      name: 'hazardous',
      lable: 'Last Contained',
      disabled: true,
      col: 8,
      rules: [
        {
          message: 'Please Enter Last Contained!',
          whitespace: true,
        },
      ],
    },
    
    {
      name: 'job_site',
      lable: 'Job Site',
      col: 8,
    },
    {
      name: 'owner',
      lable: 'Owner Name',
      col: 8,
    },
    {
      name: 'manifest',
      lable: 'Manifest No.',
      col: 8,
    },
    // {
    //   name:'custom2'
    // },
    
    {
      name: 'po_id',
      lable: 'PO No.',
      type: 'autocomplete',
      value: [],
      col: 8,
      rules: [
        {
          required: false,
          message: 'Please Enter po!',
          whitespace: true,
        },
      ],
    },
    {
      name: 'location_id',
      col: 8,
      lable: 'Yard Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select yard name!',
          whitespace: true,
        },
      ],
    },
  ];

  export const confirmation =() => [
    {
      name: 'name',
      col: 8,
      lable: 'Contact Person Name',
      placeholder: 'Enter Contact Person Name',
    },
    {
      name: 'number',
      col: 8,
      lable: 'Phone No.',
      placeholder: 'Enter Phone No.',
    },
    {
      name: 'email',
      col: 8,
      lable: 'Email Id',
      placeholder: 'Email Id',
    },
    {
      name:'sds',
      col: 8,
    },
    {
      name: 'remark',
      lable: 'Details for Bulk Items/Comments',
      type:'textarea',
      col: 16,
      placeholder: 'Type Here...',
    },
    // {
    //   name: 'number',
    //   lable: 'OR',
    //   placeholder: 'PHONE',
    // },
  ];
  export const estimate =() =>( {
    name: 'estimate',
    col: 24,
    lable: 'ESTIMATE',
    rules: [
      {
        required: true,
        message: 'Please Enter estimate!',
      },
      { type: 'number' },
      {
        pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
        message: 'Estimate value should be greater than 0 number',
      },
    ],
  })
  
  export const FoodTruck =() => [
    {
      name: 'client_cust_id',
      col: 8,
      lable: 'Customer Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select Customer!',
          whitespace: true,
        },
      ],
    },
    {
      name: 'equipment_no',
      lable: 'Trailer Unit No.',
      type: 'autocomplete',
      value: [],
      col: 8,
      rules: [
        {
          required: true,
          message: 'Please Enter Trailer Unit No.!',
          whitespace: true,
        },
      ],
    },
    

    {
      name: 'truck_number',
      lable: 'Truck No.',
      col: 8,
    },
    {
      name: 'pump_cleaning',
      lable: 'Pump Cleaning',
      type: 'radio',
      col: 6,
      value: [
        { value:false, displayValue: 'No' },
        { value:true, displayValue: 'Yes' },
      ],
      rules: []
    },
    {
      name: 'hose_cleaning',
      lable: 'Hose Cleaning',
      type: 'radio',
      col: 6,
      value: [
        { value:false, displayValue: 'No' },
        { value:true, displayValue: 'Yes' },
      ],
      rules: []
    },
    {
      name: 'quantity',
      lable: 'Hose Quantity',
      type:'number',
      disabled:false,
      col: 6,
    },
    // {
    //   name: 'wash_type_requested',
    //   lable: 'WASH TYPE REQUESTED',
    //   type: 'dropdown',
    //   value: [],
    //   col: 6,
    //   rules: [{
    //     required: true,
    //     message: 'Please Select Wash Type Request!',
    //   },]
    // },
    {
      name: 'kosher_certified',
      lable: 'Kosher Certified',
      type: 'radio',
      col: 6,
      // disabled:true,
      value: [
        { value:false, displayValue: 'No' },
        { value:true, displayValue: 'Yes' },
      ],
      rules: []
    },
    {
      name: 'last_known',
      lable: 'Last Contained #1',
      col: 8,
    },
    {
      name: 'last_known2',
      lable: 'Last Contained #2',
      col: 8,
    },
    {
      name: 'last_known3',
      lable: 'Last Contained #3',
      col: 8,
    },
    {
      name: 'po_id',
      lable: 'PO No.',
      type: 'autocomplete',
      value: [],
      col: 8,
      rules: [
        {
          required: true,
          message: 'Please Enter po number!',
          whitespace: true,        },
      ],
    },
    {
      name: 'waiting_with_trailer',
      lable: 'Waiting With Trailer',
      type: 'radio',
      col: 7,
      value: [
        { value:false, displayValue: 'No' },
        { value:true, displayValue: 'Yes' },
      ],
      rules: []
    },
    {
      name: 'location_id',
      lable: 'Yard Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select Yard Name!',
          whitespace: true,
        },
      ],
      col: 9,
    },
  ];


  export const FoodConfirmation=()=>[
    {
      name: 'driver_name',
      lable: 'Contact Name',
      col: 8,
    },
    {
      name: 'number',
      lable: 'Phone No.',
      col: 8,
      type:'number'
    },
    {
      name: 'email',
      lable: 'Email',
      col: 8,
      type:'email'
    },
    {name:'sds',  col: 8},
    {
      name: 'remark',
      lable: 'Any Special Requirement Notes Below',
      col: 8,
      type:'email'
    },
  ]
